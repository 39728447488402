import React from 'react'
import '../styles/DropDownMenu.css';

import MenuListProps from '../interface/MenuListInterface'
import Submenu from './SubMenu'

const DropDownMenu: React.FC<MenuListProps> = ({ MenuListElement, SubmenuListElement = [] }) => {
    // const submenu_view = () => {
    //     let submenu_list: Array<JSX.Element> = []

    //     for (let idx = 0; idx < MenuListElement.length; idx++) {
    //         submenu_list.push(
    //             <li key={idx} className="nav__submenu-item ">
    //                 <a>{MenuListElement[idx]}</a>
    //             </li>
    //         )
    //     }

    //     return submenu_list
    // }

    return (
        <div className="menu-container">
            <div className="menu-text">Menu</div>
            <Submenu MenuListElement={SubmenuListElement} />
        </div>
        // <nav className="nav">
        //     <ul className="nav__submenu">
        //         <li className="nav__menu-item">
        //             <a>Menu</a>
        //             <Submenu MenuListElement={SubmenuListElement} />
        //         </li>
        //     </ul>
        // </nav>
    )
}

export default DropDownMenu