import React, { useState } from 'react'
import '../styles/Home.css'
import { SwitchButton } from '../components/Button'
import { MODEL_TITLE } from '../constants/ArrayData'
import { v4 as uuidv4 } from 'uuid';

const Home = () => {
    const year = new Date().getFullYear()
    const [currentUUID, setCurrentUUID] = useState<string | undefined>("")

    const buttonList = (id: number) => {
        let array_of_button_view: Array<Array<any>> = []
        let array_part1: Array<JSX.Element> = []
        let array_part2: Array<JSX.Element> = []
        let array_part3: Array<JSX.Element> = []
        
        for (let i = 0; i < MODEL_TITLE.length; i++) {
            if (i < 3) {
                array_part1.push(
                    <SwitchButton key={i} name={MODEL_TITLE[i]} />
                )
            } else if (i >= 3 && i < 6) {
                array_part2.push(
                    <SwitchButton key={i} name={MODEL_TITLE[i]} id={currentUUID} />
                )
            } else {
                if (i > 6)array_part3.push(<div></div>)
                else{
                    array_part3.push(
                        <SwitchButton key={i} name={MODEL_TITLE[i]} />
                    )
                }
            }
        }

        array_of_button_view.push(array_part1, array_part2, array_part3)

        return array_of_button_view[id]
    }

    React.useEffect(() => {
        const uuid_store = localStorage.getItem('UUID')

        if(uuid_store === null) {
            let uuid = uuidv4()
            localStorage.setItem("UUID", uuid)
            setCurrentUUID(uuid)
        }else {
            setCurrentUUID(uuid_store)
        }
    }, [currentUUID])

    return (
        <div className="container-home">
            <header className='header'>
                <h1>Ci-dessous la liste de tous nos modèles d'IA</h1>
            </header>
            {/* <div className='header'>Ci-dessous la liste de tous nos modèles d'IA</div> */}
            <div style={{display: 'flex', flex: 2, flexDirection: 'row'}}>
                <div style={{display: 'flex', flex: 2}}></div>

                <div style={{display: 'flex', flex: 5, flexDirection: 'column'}}>
                    <div className="content-home">
                        {
                            buttonList(0)
                        }
                    </div>
                    <div className="content-home">
                        {
                            buttonList(1)
                        }
                    </div>
                    <div className="content-home">
                        {
                            buttonList(2)
                        }
                    </div>
                </div>

                <div style={{display: 'flex', flex: 2}}></div>
            </div>
            <footer className='footer'>&copy; Giris {year}. Tous droits réservés.</footer>
        </div>
    )
}

export default Home