import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Banner from '../components/Banner';
import { LoaderResponse } from '../components/Loader';
// import FormBothSideInput from '../components/FormBothSideInput';
import { TabEcrit } from '../components/AllTabs/TabEcrit';
import { TabOrale } from '../components/AllTabs/TabOrale';
import TabsMenu from '../components/TabsMenu';


import { checkLLMServerStatus, getConversationHistoryById, getLLMVersion, requestLLMService, sendPromptToLLM } from '../api/connect2llm';

import '../styles/Home.css'
import '../styles/LlmTest.css'
import { salutation_text } from '../constants/IntroText';

import { AudioPlayer } from 'react-audio-player-component';
import MicroPhone from '../components/AllTabs/MicroPhone';
import { TMicroPhone } from '../type/Microphone';

const LlmTest = () => {
    const bg_image = "/images/background7.jpg"

    const location = useLocation();

    const [choixLLM, setChoixLLM] = useState<string | null>(null);
    const [id_u, setID_u] = useState<string>("");
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [llmIsLoaded, setLlmIsLoaded] = useState<boolean | undefined>();
    const [history, setHistory] = useState<any[]>([]);
    const [llm_version, setLlm_version] = useState<string>("");
    const [parentState, setParentState] = useState<boolean>(false); // Déclencher le state qui récupère les historiques
    const [activeTab, setActiveTab] = useState<string>("tab1");
    const [creneauIsAvailable, setCreneauIsAvailable] = useState<boolean>(false);
    const [requestLoaderState, setRequestLoaderState] = useState<boolean>(false);
    const [startingLLmRequestStatus, setStartingLLmRequestStatus] = useState<boolean>(false);
    const [firstPromptState, setFirstPromptState] = useState<boolean | undefined>();
    const [emailUserFromLLM, setEmailUserFromLLM] = useState<string>("")
    // const [defaultVocal, setDefaultVocal] = useState<string | undefined>(undefined);
    const updateParentState = (newState: boolean) => {
        setParentState(newState);
    };

   

    const [urlSpecter, setUrlSpecter] = useState<string | undefined>(undefined)

    const [isLoadingAudioRecording, setisLoadingAudioRecording] = useState<boolean>(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // Déterminez comment vous voulez ajuster la largeur en fonction de la largeur de l'écran.
    // Par exemple, vous pouvez utiliser un pourcentage de la largeur de l'écran.
    const playerWidth = Math.min(0.9 * screenWidth, 1200); // Par exemple, la largeur maximale est de 90% de la largeur de l'écran ou 1200px, selon ce qui est plus petit.


    //Fonction qui converit un text en audio
    function createBlobUrl(base64data: string, mimeType: string): string {
        // Convertir la chaîne base64 en un objet Blob
        const binaryString: string = window.atob(base64data);
        const bytes: Uint8Array = new Uint8Array(binaryString.length);

        for (let i: number = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const blob: Blob = new Blob([bytes], { type: mimeType });

        // Créer une URL à partir du Blob
        const url: string = URL.createObjectURL(blob);

        return url;
    }

    // Gérer la navigation à gauche (menu)
    const handleTab = (newState: string) => {
        setActiveTab(newState);
        if (newState === 'accueil') {
            window.open(`/`);
            setInterval(() => {
                window.close()
            }, 1000)
        }
    };

    // Mets à jour le state que le LLM est en ligne ou hors ligne
    const updateLlmStat = (newState: boolean) => {
        setLlmIsLoaded(newState);
    };

    const runFirstPrompt2Llm = (state: boolean) => {
        // Attends le status du llm, puis autorise le déclenchement d'envoi du message d'accueil (True)
        /**
         * True : autoriser le lancement du prompt de salutation
         * False: révoquer l'autorisation car c'est déjà fait
         * undefined: aucun status
         */
        if (firstPromptState === undefined) {
            setFirstPromptState(state)
        }
    }

    const year = new Date().getFullYear()

    const generateRandomString = (length: number) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    }

    const sautLigne = (text: string, sep = "\n") => {
        const lines = text.split(sep)
        const divArrayView: Array<JSX.Element> = []
        // console.log("Text: ", text)
        // console.log("Lines: ", lines)
        lines.map((line, index) => (
            divArrayView.push(<div dangerouslySetInnerHTML={{ __html: line }} key={index} />)
        ))

        return divArrayView
    }

    const verifyFirstPrompt = (modelName: string, textValue: string): boolean => salutation_text.some(msg => msg.model_name === modelName && msg.text_value === textValue);

    // Vue pour l'historique de conversation
    const createConversationalHistoryView = () => {
        let hist_view: Array<JSX.Element> = []
    
        const dataMicrophone : TMicroPhone = {
            random_id : id_u,
            model_name :choixLLM!,
            setHistory :setHistory,
            setShowHistory:setShowHistory,
            setisLoadingAudioRecording :setisLoadingAudioRecording,
            setUrlSpecter : setUrlSpecter
        }

        for (let idx = 0; idx < history.length; idx++) {
            const verify = verifyFirstPrompt(String(choixLLM), history[idx].human)
            console.log("verify", verify)
            if (verify) {
                // On retire le premier prompt déclencheur de conversation
            } else {
                hist_view.push(
                    <div className="rowMicro">
                        {
                            ((idx === history.length - 1) && activeTab === "tab2" )&&
                            <div>
                                <MicroPhone data={dataMicrophone}/>
                            </div>
                        }
                        <div key={2 * idx} className="history-user-message col-message" style={(activeTab === "tab2"? (idx!== history.length - 1? { marginLeft: "14%" } : {}) : { marginLeft: "14%" })}>
                            <div className="user-name">UTILISATEUR</div>
                            <div className="text-message">{history[idx].human}</div>
                            {/*  {(history[idx].voice_human && history[idx].voice_human !== "") ? (<AudioPlayer
                            src={createBlobUrl(history[idx].voice_human, 'audio/mpeg')}
                            minimal={true}
                            width={screenWidth * 0.6}
                            trackHeight={40}
                            barWidth={3}
                            gap={1}
                            visualise={true}
                            backgroundColor="#367b4700"
                            barColor="#FFFFF"
                            barPlayedColor="#F0000"
                            skipDuration={2}
                            showLoopOption={true}
                            showVolumeControl={true}
                            hideSeekBar={true}
                            hideSeekKnobWhenPlaying={true}
                        />) : <></>} */}
                        </div>
                    
                    </div>
                )
            }
            hist_view.push(
                <>
                <div key={2 * idx + 1} className="history-llm-message">
                    <div className="user-name">{choixLLM?.toUpperCase()}</div>
                    {choixLLM === "Stéphane" ?
                        <div className="text-message">{displayResponseByKeyWord(history[idx].ai)}</div>
                        // <div className="text-message">{checkCreneauDisponible(history[idx].ai)}</div>
                        : <div className="text-message">{sautLigne(history[idx].ai)}</div>
                    }
                    {/* {(history[idx].voice_ai && history[idx].voice_ai !== "") ? (
                        <>
                    <AudioPlayer
                        src={createBlobUrl(history[idx].voice_ai, 'audio/mpeg')}
                        minimal={true}
                        width={screenWidth * 0.6}
                        trackHeight={40}
                        barWidth={3}
                        gap={1}
                        visualise={true}
                        backgroundColor="#104239eb"
                        barColor="#FFFF"
                        barPlayedColor="#000000"
                        skipDuration={2}
                        showLoopOption={true}
                        showVolumeControl={true}
                        hideSeekBar={true}
                        hideSeekKnobWhenPlaying={true}
                        autoplay= {idx === history.length - 1}
                    />
                     </>
                    ) : (<></>)} */}

                </div>
                {
                (verify && (idx === history.length - 1) && activeTab === "tab2" )  &&
                  <div className='firstMic'><MicroPhone data={dataMicrophone}/></div> }
            </>
            )
        }
        
        console.log("hist_view.length" ,hist_view.length);
        return hist_view
    }

    // Récupérer les historiques de conversation depuis mongodb à partir de l'iduser
    const getConversationalHistoryFromDB = useCallback(() => {
        const hist = getConversationHistoryById(id_u, String(choixLLM));

        hist.then((resp: any) => {
            let array_d = resp.data.history;

            setHistory(array_d);
            setShowHistory(true);
        })
            .catch((e) => {
                // Handle error
                // console.error(e);
            });
    }, [id_u, choixLLM]);

    const displayRoadMapByNumber = (texte: string) => {
        // Diviser le texte en lignes
        const lignes = texte.split("\n");

        // Parcourir chaque ligne
        for (let i = 0; i < lignes.length; i++) {
            const ligne = lignes[i];
            // Vérifier si la ligne contient un point précédé d'un chiffre
            if (/\d\./.test(ligne)) {
                // Remplacer le point par un point et un saut de ligne
                lignes[i] = ligne.replace(/(\d+)\./g, "$1.\n");
            }
        }

        // Rejoindre les lignes pour former le texte final
        const texteFinal = lignes.join("\n");
        return texteFinal;
    }

    const displayResponseByKeyWord = (llm_pred: string) => {
        if (llm_pred.includes("\n")) {
            return sautLigne(llm_pred)
        }
        else if (llm_pred.includes("\\n")) {
            return sautLigne(llm_pred, "\\n")
        }
        else if (llm_pred.includes("roadmap")) {
            return displayRoadMapByNumber(llm_pred)
        }
        else if (llm_pred.includes("Voici quelques propositions de date")) {
            return checkCreneauDisponible(llm_pred)
        } else {
            return llm_pred
        }
    }

    // Fonction qui vérifie qu'il y a des créneaux disponibles dans la réponse
    const checkCreneauDisponible = (llm_pred: string) => {
        const mot_cle = "Voici quelques propositions de date"

        const llm_response_split = llm_pred.split(",")

        type ButtonClickHandler = (word: string) => void;

        const createButton = (word: string, onClick: ButtonClickHandler): JSX.Element => (
            <button className={creneauIsAvailable ? "creneau-btn-deactive" : "creneau-btn"} key={word} onClick={() => onClick(word)}>
                {word}
            </button>
        );

        const convertDateToFrenchFormat = (dateString: string): string => {
            const monthsEn = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            const monthsFr = [
                'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
            ];

            // eslint-disable-next-line
            const [day, month, year, atTime, ...timeArray] = dateString.split(/[\s,]+/);
            const time = timeArray.join(' ');
            const monthIndex = monthsEn.indexOf(month);

            if (monthIndex !== -1) {
                const monthFr = monthsFr[monthIndex];
                return `${day} ${monthFr} ${year} à ${time}`;
            }

            // Retourne la date d'origine si le mois n'est pas trouvé
            return dateString;
        };

        // eslint-disable-next-line
        const convertFrenchDateToEnglishFormat = (dateString: string): string => {
            const monthsEn = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            const monthsFr = [
                'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
            ];

            // eslint-disable-next-line
            const [day, month, year, atTime, ...timeArray] = dateString.split(/[\s,]+/);
            var times = timeArray.join(' ');
            var time_temp = times.split("h")
            const time = time_temp.join(":")
            const monthIndex = monthsFr.indexOf(month);

            if (monthIndex !== -1) {
                const monthEn = monthsEn[monthIndex];
                return `${day} ${monthEn} ${year} at ${time}`;
            }

            // Retourne la date d'origine si le mois n'est pas trouvé
            return dateString;
        };

        const extractWordsStartingWith = (input: string, separator: string): string[] => {
            const words = input.split(separator);
            words.shift(); // effacer le premier élément

            const convertedDates = words
                .filter((word) => word.trim())
                .map((dateString) => convertDateToFrenchFormat(dateString));

            return convertedDates;
        };

        const processInput = (input: string, keyword: string, onButtonClick: ButtonClickHandler): JSX.Element | boolean => {
            if (input.includes(keyword)) {
                const extracted_datetime = extractWordsStartingWith(input, "$");
                const extracted_email = extractWordsStartingWith(llm_response_split[1], "_$");

                if (emailUserFromLLM === "") {
                    setEmailUserFromLLM(extracted_email[0])
                }

                if (extracted_datetime.length > 0) {
                    return (
                        <div className="creneau-btn-container">
                            <p style={{ marginBottom: '10px' }}>{keyword} : </p>
                            <div style={{ display: "flex", flex: 1 }}>
                                {extracted_datetime.map((word) => createButton(convertFrenchDateToEnglishFormat(word), onButtonClick))}
                            </div>
                        </div>
                    );
                } else {
                    // console.log("Aucun mot ne commence par un $.")
                    return false
                }
            } else {
                // console.log("Le mot clé n'est pas présent dans le texte d'entrée.")
                return false
            }
        };

        const buttonClickHandler: ButtonClickHandler = (word) => {
            if (creneauIsAvailable) {

            } else {
                setRequestLoaderState(true)
                let final_text = `Puis-je prendre rendez-vous pour le ${word}. Avec ${emailUserFromLLM}`
                // let final_text = `Puis-je prendre rendez-vous pour le ${word}. Avec adriana@diris.fr et stephane.diris@gmail.com comme participants. La description du rendez-vous sera 'Projet IA'.`

                const res = sendPromptToLLM(id_u, final_text, choixLLM)
                res.then(() => {

                    setCreneauIsAvailable(true)
                    getConversationalHistoryFromDB()
                    setRequestLoaderState(false)
                }).catch((e) => {
                    setCreneauIsAvailable(false)
                    setRequestLoaderState(false)
                })
            }
        };

        // Appel des fonctions
        const result = processInput(llm_response_split[0], mot_cle, buttonClickHandler);

        return !result ? llm_pred : result
    }

    const initLlmService = () => {
        setStartingLLmRequestStatus(true)
        setLlmIsLoaded(undefined)

        requestLLMService(choixLLM).then((response) => {
            // let rep = response

            // Vérifier tous les 7 secondes le status
            setTimeout(() => {
                checkLLMServerStatus(choixLLM)
                    .then((resp: any) => {
                        let data = resp
                        let etat = data.status === "active" ? true : false
                        setLlmIsLoaded(etat)
                        setStartingLLmRequestStatus(etat)
                        if (llmIsLoaded && (choixLLM === "Stéphane" || choixLLM === "Adriana")) {
                            setFirstPromptState(llmIsLoaded)
                        }
                    })
                    .catch((e) => {

                    })
            }, 7000); // Délai de 7 secondes (7 000 millisecondes)
        }).catch((e) => {
            setStartingLLmRequestStatus(false)
            setLlmIsLoaded(undefined)
        })
    }

    const messagesRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Extraire la valeur du paramètre 'model' de l'URL
        const params = new URLSearchParams(location.search);
        const argValue = params.get('model');
        setChoixLLM(argValue)

        setID_u(generateRandomString(16))
    }, [location.search]);

    useEffect(() => {
        const LLMVersion = () => {
            const res = getLLMVersion(String(choixLLM))

            res.then((resp) => {
                if (resp) setLlm_version(resp.data.version)
            })
                .catch((e) => {
                    console.error(e)
                })
        }

        // On récupère la version du LLM utilisé après le choix du model
        if (choixLLM) LLMVersion()
    })

    useEffect(() => {
        const fetchData = async () => {
            if (choixLLM != null) {
                getConversationalHistoryFromDB();
                setParentState(false);
            }
        };

        // Désactiver la récupération de l'historique de conversation pour le model Aurelia
        if (parentState && choixLLM !== 'Aurélia') {
            fetchData();
        }

        // Fonction pour effectuer le scroll vers le bas
        const scrollToBottom = () => {
            if (messagesRef.current) {
                const scrollHeight = messagesRef.current.scrollHeight;
                const clientHeight = messagesRef.current.clientHeight;
                const maxScrollTop = scrollHeight - clientHeight;
                messagesRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            }
        };

        // Appeler la fonction d'auto-scroll dès que les messages sont mis à jour
        scrollToBottom();
    }, [id_u, choixLLM, parentState, history, getConversationalHistoryFromDB]);

    // Déclencher la salutation une fois ansi que l'historique de converasation
    useEffect(() => {
        var welcoming_text = ""

        if (firstPromptState) {
            if (choixLLM === "Stéphane" || choixLLM === "Adriana") {
                salutation_text.forEach((item, id) => {
                    if (choixLLM === item.model_name) welcoming_text = item.text_value
                })
                sendPromptToLLM(id_u, welcoming_text, choixLLM)
                    .then((data: any) => {
                        //console.log("SendPrompt", data)
                        setUrlSpecter(createBlobUrl(data.data.audio, 'audio/mpeg'))
                        setParentState(true) // déclencher l'historique
                        setFirstPromptState(false) // annuler la permission à lancer le premier prompt
                        // }
                    })
                    .catch((e) => { console.error("prompt 1 error", e) })
            } else {
                setFirstPromptState(false) // annuler la permission à lancer le premier prompt
            }
        }
    }, [firstPromptState, choixLLM, id_u])

    const createTutorial4Aurelia = () => {
        let div_array: Array<JSX.Element> = []

        div_array.push(
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 20, margin: 10, borderRadius: 10, borderWidth: 1, background: "#ffffffbf", alignItems: "center", height: 460 }}>
                    <p style={{ color: 'rgb(32, 166, 47)' }}>Prompt pour générer un mail avec Aurélia :</p>
                    <p style={{ fontSize: 'medium', fontWeight: 'normal' }}>
                        Ecris à [nom et prénom de la cible], [poste] chez [entreprise], à l'adresse [adresse mail de destination]<br></br><br></br>
                        Exemple :  Ecris à Nico Dupont, Chief Information Officer Innovation chez Dupont Company, à l'adresse mailto:monadresse@gmail.com<br></br><br></br>
                    </p>

                    <p style={{ color: 'rgb(32, 166, 47)' }}>Prompt pour chercher un profil dans une entreprise avec Aurélia :</p>
                    <p style={{ fontSize: 'medium', fontWeight: 'normal' }}>
                        Utilise Worker search pour rechercher un [poste] chez [entreprise] en donnant son poste ainsi que son adresse linkedin, et envoi le résultat à l'adresse mail mailto:monadresse@gmail.com<br></br><br></br>
                        Exemple : Utilise Worker search pour rechercher un Directeur Innovation chez Dupont Company en donnant son poste ainsi que son adresse linkedin et envoi le résultat à l'adresse mail mailto:monadresse@gmail.com
                    </p>
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 15, margin: 10, borderRadius: 10, borderWidth: 1, background: "#ffffffbf", alignItems: "center", height: 260 }}>
                    <p style={{ color: 'rgb(32, 166, 47)' }}>Prompt pour générer une réponse à une requête :</p>
                    <p style={{ fontSize: 'medium', fontWeight: 'normal' }}>
                        Utilise Mail response pour répondre à cette requête : [nom et prénom], [adresse mail de destination], [requête de la personne]<br></br><br></br>
                        Exemple :  Utilise Mail response pour répondre à cette requête : Nico Dupont, mailto:monadresse@gmail.com, IA assistant virtuel
                    </p>
                </div>
            </div>
        )

        return div_array
    }

    return (
        choixLLM != null ? (
            <div className='llm-page-container'>

                <div className="wrapper" style={{ backgroundColor: `white` }}>
                    <div className="left-part">
                        {/* <div className='left-part-menu'>Menu</div> */}
                        <TabsMenu model_name={choixLLM} random_id={id_u} firstTabName='Prompt écrit' secondTabName='Prompt vocal' updateParentState={handleTab} />
                        <footer className='right-part-footer'>&copy; Giris {year}. Tous droits réservés.</footer>
                    </div>

                    <div className="right-part">
                        <header className='header-top'>
                            <div className='header-left-part'>
                                {llm_version}
                            </div>
                            <div className='header-middle-part'>
                                <p className='header-middle-part-title'>Bonjour et Bienvenue. Vous êtes en présence de {choixLLM}</p>
                            </div>
                            <div className='header-right-part'>
                                <Banner model_name={choixLLM} user_id={id_u} getLlmStatus={updateLlmStat} updateParentState={runFirstPrompt2Llm} />
                            </div>
                        </header>

                        <div className='right-part-content-form' style={choixLLM && !llmIsLoaded ? { justifyContent: "center", alignItems: "center" } : {}}>
                            {
                                choixLLM && !llmIsLoaded ? (
                                    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                        {
                                            startingLLmRequestStatus && llmIsLoaded === undefined ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <LoaderResponse colorValue='white' />
                                                    <p>Demande en cours...</p>
                                                </div>
                                            ) : (
                                                llmIsLoaded === undefined ? null : <button onClick={initLlmService} className='creneau-btn' style={{ padding: 25, fontSize: "large" }}>Cliquez ici pour commencer</button>
                                            )
                                        }
                                    </div>
                                ) : (

                                    <div className="todos">
                                        <div ref={messagesRef} className='right-part-history-container'>
                                            {
                                                choixLLM !== "Aurélia"
                                                    ? showHistory && createConversationalHistoryView()
                                                    : (
                                                        createTutorial4Aurelia()
                                                    )
                                            }
                                        </div>
                                        {
                                            requestLoaderState ? (
                                                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                    <LoaderResponse colorValue='white' />
                                                </div>
                                            ) : (
                                                firstPromptState === false && (
                                                    activeTab === "tab1" ?
                                                        <>
                                                            <TabEcrit
                                                                model_name={choixLLM}
                                                                random_id={id_u}
                                                                updateParentState={updateParentState}
                                                                llmStat={llmIsLoaded}
                                                                LoaderStateDefaultValue={requestLoaderState}
                                                            />
                                                        </>
                                                        : <>
                                                            {/* {
                                                choixLLM !== "Aurélia"
                                                    ? showHistory && createConversationalHistoryView()
                                                    : (
                                                        createTutorial4Aurelia()
                                                    )
                                            }  */}
                                                            {urlSpecter && <TabOrale
                                                                model_name={choixLLM}
                                                                random_id={id_u}
                                                                setHistory={setHistory}
                                                                setShowHistory={setShowHistory}
                                                                urlSpecter={urlSpecter}
                                                                isLoadingAudioRecording={isLoadingAudioRecording}
                                                            />}
                                                        </>
                                                )
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <LoaderResponse w={100} h={100} />
            </div>

        )
    )
}

export default LlmTest
