export const useAutosizeTextArea = (inputRef: HTMLDivElement| HTMLTextAreaElement | null, value: string, element_type: string) => {
    if (inputRef) {
        if(value){
            // Réinitialiser la hauteur du texte area, div
            inputRef.style.height = '0px'
            const { scrollHeight } = inputRef

            if(element_type === 'div'){
                // On limite à 50px minimum la taille de départ du div, avant qu'il puisse s'aggrandir en fonction du texte contenu dans le textearea
                inputRef.style.height = scrollHeight < 50 ? '50px' : `${scrollHeight}px`
            }else{
                // On garde la hauteur actuel pour textearea
                inputRef.style.height = `${scrollHeight}px`
            }
        }else {
            // Texte vide, on garde 50px de hauteur pour le conteneur div, et en remet à l'initial pour textearea
            inputRef.style.height = element_type === 'div' ? '50px' : 'auto'
        }
    }
}