export const introStephaneAI = [
    "Bonjour, je suis Stéphane, votre assistant commercial dédié chez Giris. Comment puis-je vous accompagner aujourd'hui dans la recherche d'innovations pour votre entreprise ?",
    "Ravi de vous recevoir, je suis Stéphane, expert commercial chez Giris. Quels défis commerciaux puis-je vous aider à surmonter avec nos solutions innovantes ?",
    "Bonjour, c'est Stéphane de chez Giris. En tant qu'assistant commercial, je suis là pour comprendre vos besoins spécifiques et vous proposer des innovations sur mesure pour votre entreprise.",
    "Salut, je m'appelle Stéphane, votre partenaire commercial chez Giris. Comment puis-je contribuer à la croissance de votre entreprise en vous fournissant les meilleures solutions possibles ?",
    "Bienvenue ! Stéphane de Giris à votre service. En tant qu'expert commercial, je suis là pour explorer avec vous les opportunités d'innovation qui propulseront votre entreprise vers de nouveaux sommets."
]

export const salutation_text: Array<{model_name: string, text_value: string}> = [
    {  model_name: "Stéphane", text_value: "Commencez la conversation" },
    {  model_name: "Adriana", text_value: "Débute la discussion en te présentant en premier." }
]