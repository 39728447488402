export const MODEL_TITLE = [
    "Lisa",
    "Dr Violet",
    "Stéphane",
    "Adriana",
    "Aurélia",
    "Pr Violet",
    "Nakamoto"
  ]
  
export const REAL_MODEL_NAME = [
    "https://ia-generative.giris.fr:8443/langcvllm",
    "https://ia-generative.giris.fr:8444/violet",
    "https://ia-generative.giris.fr:8445/stephane",
    "https://ia-generative.giris.fr:8447/simulabot",
    "https://models.giris.fr:8444/aurelia",
    "https://models.giris.fr:8443/pr_violet",
    "https://models.giris.fr:8445/llm_prediction",
]

export const PYTHON_API_URL = [
  "https://ia-generative.giris.fr:8443", // Lisa
  "https://ia-generative.giris.fr:8444", // Dr Violet
  "https://ia-generative.giris.fr:8445", // Stéphane
  "https://ia-generative.giris.fr:8447", // Adriana
  "https://models.giris.fr:8444", // Aurélia
  "https://models.giris.fr:8443", // Pr Violet
  "https://models.giris.fr:8445", // Nakamoto
]

export const MODEL_DESCRIPTION = [
  "IA EN ENVIRONNEMENT 3D",
  "LE PROFESSEUR QUI ENSEIGNE À CODER",
  "INGENIEUR AVANT-VENTE",
  "IA GESTION FISCALE STRATEGIQUE",
  "EMAIL SALES SPECIALIST",
  "LE PROFESSEUR QUI VERIFIE ET CORRIGE LES CODES",
  "ANALYSTE DES MARCHES DE CRYPTOMONNAIE"
]

export const MESSAGE = [
  "Lisa",
  "Dr Violet",
  "Le processus est en cours, et les détails de la réunion seront envoyé à votre adresse e-mail. Veuillez le vérifier.",
  "Adriana",
  "Le processus est en cours, et le résultat sera envoyé à votre adresse e-mail. Veuillez le vérifier à partir de maintenant.",
  "Pr Violet",
  "Nakamoto"
]