import React, { useState, useEffect } from 'react';
import { checkLLMServerStatus } from '../api/connect2llm';
import { LlmStatusLoader } from './LLMStatusLoader';

import "../styles/Banner.css"
import { COLORS } from '../constants/ColorsData';

// Définir le type pour le statut
type Status = 'active' | 'inactive';

interface BannerProps {
    model_name: string | null;
    user_id: string;
    getLlmStatus?: any;
    updateParentState?: any;
}

const Banner: React.FC<BannerProps> = ({model_name, getLlmStatus, updateParentState}) => {
    const [status, setStatus] = useState<Status | string>('inactive');
    const [loader, setLoader] = useState<boolean>(false);
    
    const online_icon = "/images/icone/model_on_1.svg"
    const offline_icon = "/images/icone/model_off.svg"

    useEffect(() => {
        const interval = setInterval(() => {
            checkLLMServerStatus(model_name).then((res) => {
                setLoader(true)
                setStatus(res.status)
                const state = res.status === 'active' ? true : false
                getLlmStatus(state)
                updateParentState(state)
                // if(llmIsReady === undefined) setLlmIsReady(state)
            }).catch((e) => {
                setStatus('inactive')
            })
        }, 5000);

        // Nettoyez l'intervalle lorsque le composant est démonté ou lorsque vous ne voulez plus qu'il soit actif
        return () => clearInterval(interval);
    }, [model_name, getLlmStatus, updateParentState])

    return (
        <>
            {
                !loader ? <LlmStatusLoader /> : (
                    <div className="banner">
                        {/* Première partie avec l'icône */}
                        <div className="part-one">
                            <img
                                src={status === 'active' ? online_icon : offline_icon}
                                alt={status === 'active' ? "on" : "off"}
                                width="30"
                                height="30"
                            />
                            <p style={{color: status === 'active' ? COLORS.darkgreen : COLORS.rouge, fontSize: 'small'}}>
                                {status === 'active' ? "En ligne" : "Hors ligne"}
                            </p>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default Banner;