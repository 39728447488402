export const Redirect2NewPage = (llm_name: string, text?: string, id?: string) => {
    if(llm_name === "Dr Violet" || llm_name === "Pr Violet"){
        window.open('https://genius27.com/', '_blank')
      }
      else if(llm_name === "Lisa"){
        window.open('https://randstad.giris.fr/', '_blank')
      }
      else if(llm_name === "accueil"){
        window.open("/")
        window.setTimeout(() => {
          window.close();
        }, 1000); 
      }
      else if(llm_name === "Aurélia" && text === "/info"){
        window.location.replace("/info/" + llm_name)
      }
      else if(llm_name === "Aurélia" && text === ""){
        window.open(`/form/${llm_name}/${id}`);
        window.setTimeout(() => {
          window.close();
        }, 1000); 
      }
      else {
        window.open(`/llm/?model=${llm_name}`);
        window.setTimeout(() => {
          window.close();
        }, 1000);        
    }    
}