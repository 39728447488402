import React, { useEffect } from "react";
import FormInputProps from "../interface/FormInputInterface";
import MultiLineInput from "./MultilineInput";

import { sendPromptToLLM } from "../api/connect2llm";
import { LoaderResponse } from "./Loader";
import { introStephaneAI } from "../constants/IntroText";
import { useAutosizeTextArea } from "./AutoSizeTextArea";

import '../styles/FormInput.css'

const FormInput: React.FC<FormInputProps> = ({model_name = null, random_id ="", user_prompt="", llm_pred="", showForm=true, updateParentState, llmStat=false, LoaderStateDefaultValue=false}) => {
    const touch_init = "/images/icone/touch_init.svg"
    const touch_send = "/images/icone/touch_send.svg"

    // eslint-disable-next-line
    const [message, setMessage] = React.useState(user_prompt)
    const [isInputEmpty, setIsInputEmpty] = React.useState(true)
    const [llmRequestRuning, setLlmRequestRuning] = React.useState(LoaderStateDefaultValue)
    const inputRef = React.useRef<any>(null);
    const containerRef = React.useRef<HTMLDivElement>(null)

    // État pour stocker la valeur de l'input
    const [inputValue, setInputValue] = React.useState<string>(user_prompt);
    
    // Touche Entrée, pour lancer la requête
    const handleEnterKeyPressed = (value: boolean) => {
        if(value){
            handleSubmit()
        }
    }

    React.useEffect(() => {
        if(inputValue){
            setIsInputEmpty(false)
        }else{
            setIsInputEmpty(true)
        }
    }, [inputValue])

    // Augmente la hauteur du champ de prompt en fonction du texte en entrée
    useAutosizeTextArea(inputRef.current, inputValue, "textarea")
    useAutosizeTextArea(containerRef.current, inputValue, "div")

    // Envoie du requête de réponse vers le LLM
    const handleSubmit = () => {
        setLlmRequestRuning(true)
        
        const res = sendPromptToLLM(random_id, inputValue, model_name)
        res.then((response: any) => {
            const prediction_text = response.data.text
            setMessage(prediction_text)
            updateParentState(true);
            setInputValue("")
            setIsInputEmpty(true)
            setLlmRequestRuning(false)
        }).catch((e) => {
            setMessage("Aucune réponse venant du llm.")
            setLlmRequestRuning(false)
        })   
    }

    const setDefaultIAMessage = () => {
        let rand_indice = Math.floor(Math.random() * introStephaneAI.length);

        setMessage(introStephaneAI[rand_indice])
    }

    useEffect(() => {
        if (model_name === "Stéphane" && llmStat) setDefaultIAMessage()
    }, [model_name, llmStat])

    return (
        <>
            {
                llmRequestRuning ? (
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <LoaderResponse colorValue="white" />
                    </div>
                ) : (
                    showForm && 
                        <form onSubmit={e => e.preventDefault()}>
                            <div ref={containerRef} className="form-container">
                                <div className="textarea-container">
                                    <MultiLineInput inputContent={inputValue} setInputValue={setInputValue} setEnterKeyPressed={handleEnterKeyPressed} inputRef={inputRef} inputPlaceholder="Tapez ici votre question ou demande..." />
                                </div>                        
                                
                                <div className="send-btn-container">
                                    <button 
                                        onClick={handleSubmit} 
                                        className="input-submit-btn" 
                                        disabled={isInputEmpty} 
                                        style={
                                            isInputEmpty ? {background: 'transparent', cursor: 'default'} : {background: "transparent", cursor: 'pointer'}
                                        }
                                        >
                                        <img src={isInputEmpty ? touch_init: touch_send} alt="E" width={40} height={40} />
                                    </button>
                                </div>
                            </div>
                        </form>
                )
            }
        </>
      );
      
}

export default FormInput