export const COLORS = {
    vertClaire: "#5BFF8796",
    texteVertClaire: "#B6FFCB",
    vertFonce: "#0E2627",
    vertFonceTransp: "#367B4763",
    bleuClaireVerdatre: "#C2FFC4",
    gris: "#eee9",
    rouge: "#d01212",
    colorBlue: "#00aeef",
    colorBlueDark: "#0d2035",
    submenuWidth: "180px",
    darkgreen : "darkgreen"
}