import { Link } from "react-router-dom";
import "../styles/NotFound.css"

const ErrorPage = () => {
    return (
        <div className="error-content-container">
            <h1>Oops! Vous semblez être perdu.</h1>
            <h3>Voici un lien pour revenir dans le bon chemin:</h3>
            <Link to='/'>Accueil</Link>
        </div>
    )
}

export default ErrorPage