import React, { useState } from "react";
import { useParams } from "react-router-dom";

import '../styles/Dash.css'

import { UserDataToSend2Aurelia } from "../type/UserDataEvent";
import DropDownMenu from "../components/DropdownMenu";
import { sendPromptToLLM } from "../api/connect2llm";
import { LoaderResponse } from "../components/Loader";
import { Redirect2NewPage } from "../Helpers/Redirect2";

const LLM_NAME = "Aurélia"

const Dash = () => {
    const [requestStatus, setRequestStatus] = useState(false);
    
    const [userFormData, setFormData] = useState<UserDataToSend2Aurelia>({
        user_id: "",
        firstname: "",
        lastname: "",
        email_adress: "",
        job: "",
        activity_domain: "",
        target_position: ""
    });

    const params = useParams();
    const user_id = params.id
    // const llm_name = params.model_name

    // const updateUserId = (val: string) => {
    //     setFormData(prevState => ({ ...prevState, user_id: val }));
    // }

    const updateFirstName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, firstname: val.target.value }));
    }

    const updateLastName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, lastname: val.target.value }));
    }

    const updateProfession = (val: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, job: val.target.value }));
    }

    const updateDomaineActvity = (val: React.ChangeEvent<HTMLInputElement>) => {
        if(val !== undefined) setFormData(prevState => ({ ...prevState, activity_domain: val.target.value }));
    }

    const updatePosteCible = (val: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, target_position: val.target.value }));
    }

    const updateEmailAdress = (val: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, email_adress: val.target.value }));
    }

    const sendFormaData2Aurelia = () => {
        // Basic validation before submitting the form
        if (!userFormData.firstname || !userFormData.lastname || !userFormData.email_adress || !userFormData.target_position || !userFormData.activity_domain || !userFormData.job) {
            alert("Champs incomplètes !!!")
            return;
        }

        if(user_id){
            setRequestStatus(true)
            const prompt =  "Utilise Worker search pour rechercher un " + userFormData.target_position + " dans le domaine du " + userFormData.activity_domain + ", en donnant son poste ainsi que son adresse linkedin et envoi le résultat à l'adresse " + userFormData.email_adress

            const res = sendPromptToLLM(user_id, prompt, LLM_NAME)
            res.then(() => {
                setRequestStatus(false)
                Redirect2NewPage(LLM_NAME, "/info")
            })
            .then(() => {
                setRequestStatus(false)
            })
        }
    }

    return (
        <div className="dash-main-container">
            <section className="dash-left-part">
                <DropDownMenu MenuListElement={[""]} />
            </section>

            <section className="dash-right-part">
                <div style={{display: "flex", height: 50, alignItems: "center", justifyContent: 'center', fontSize: 30}}>Formulaire</div>
                <div id="dash-form-right-content">
                    <div style={{display: 'flex', flex: 2, flexDirection : 'column'}}>
                        <div id="dash-title">Remplir les champs</div>

                        <div id="form-input">
                            <div className="col1">
                                <div className="row">
                                    <label>Votre prénom *</label>
                                    <input type="text" placeholder="Prénom" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateFirstName(val)} required={true} style={{width: '95%'}}  />
                                </div>
                                <div className="row">
                                    <label>Votre nom *</label>
                                    <input type="text" placeholder="Nom" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateLastName(val)} required={true}/>
                                </div>
                            </div>
                            <div className="row">
                                <label>Email *</label>
                                <input type="email" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateEmailAdress(val)} placeholder="Adresse e-mail pour l'envoi de rapport" required={true}/>
                            </div>
                            <div className="row">
                                <label>Profession *</label>
                                <input type="text" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateProfession(val)} placeholder="Votre profession" required={true}/>
                            </div>
                            <div className="row">
                                <label>Domaine d'activité cible *</label>
                                <input type="text" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateDomaineActvity(val)} placeholder="Votre domaine d'activité" required={true}/>
                            </div>
                            <div className="row">
                                <label>Poste de la cible visé *</label>
                                <input type="text" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updatePosteCible(val)} placeholder="Le poste ciblé pour la prospection" required={true}/>
                            </div>

                            <div id="form-btn-container" style={{alignItems: "start"}}>
                                {
                                    requestStatus ? (
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <LoaderResponse colorValue='green' />
                                            <p style={{color: 'green'}}>Envoi en cours...</p>
                                        </div>
                                    ) : (
                                    <button id="form-btn" onClick={sendFormaData2Aurelia}>Envoyer</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{display: 'flex', flex: 1, fontWeight: 'bold'}}>
                        * : Obligatoire
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Dash
