import React from 'react';
import '../styles/MultiLineInput.css'; // Assurez-vous de créer ce fichier CSS et d'ajuster le chemin

interface MultiLineInputProps {
    inputContent: string;
    setInputValue: (new_text: string) => void;
    setEnterKeyPressed: (new_stat: boolean) => void;
    inputRef?: React.LegacyRef<HTMLTextAreaElement> | undefined;
    inputPlaceholder?: string;
}

const MultiLineInput: React.FC<MultiLineInputProps> = ({inputContent, setInputValue, setEnterKeyPressed, inputRef, inputPlaceholder='Placeholder'}) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const enterKeyPressed = () => {
    setEnterKeyPressed(true)
  }

  return (
    // <div className="multiline-input-container">
      <textarea
        ref={inputRef}
        id="multilineInput"
        value={inputContent}
        onChange={handleInputChange}
        rows={3} // Vous pouvez ajuster le nombre de lignes souhaité
        // cols={50} // Vous pouvez ajuster la largeur du champ
        // className="multiline-input"
        placeholder={inputPlaceholder}
        onKeyDown={(e) => {
            if(e.key === 'Enter' && !e.shiftKey) enterKeyPressed()
          }
        }
        autoFocus={true}
      />
    // </div>
  );
};

export default MultiLineInput;
