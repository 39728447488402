import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import LlmTest from "../pages/LlmTest";
import AudioPage from "../pages/AudioPage";
//import TabPage from "../components/Tabs";
import FormCalendar from "../pages/FormCalendar";
import ErrorPage from "../pages/NotFound";
import Dash from "../pages/AureliaDashB";
import Information from "../pages/Information";
import Sso from "../pages/Sso";
import { TabEcrit } from "../components/AllTabs/TabEcrit";
import { TabOrale } from "../components/AllTabs/TabOrale";

const AllRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/llm" element={<LlmTest />} />
      {/* <Route path="/tab" element={<TabPage />} /> */}
      <Route path="/audio" element={<AudioPage />} />
      <Route path="/info/:model_name" element={<Information />} />
      <Route path="/form/:model_name/:id/" element={<Dash />} />
      <Route path="/meeting/calendar/:id/" element={<FormCalendar />} />
      <Route path="/login" element={<Sso />} />
    </Routes>
  )
}

export default AllRoutes