import React from 'react'
import { Circles } from 'react-loader-spinner'
import LoaderProps from '../interface/LoaderInterface'

export const LoaderResponse: React.FC<LoaderProps> = ({classname, w=50, h=50, colorValue="#1c4008"}) => {
    const WIDTH = String(w)
    const HEIGHT = String(h)

    return (
        <div className={classname}>
            <Circles
                height={WIDTH}
                width={HEIGHT}
                color={colorValue}
                // color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
        </div>
    )
}