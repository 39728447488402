import { Navigate } from "react-router-dom";
import Unauthorized from "../../pages/Unauthorized";

const hasRoles = (roles) => {
  const userRole = localStorage.getItem('userRole');
  return roles.find((role) => String(userRole) === String(role))
}

export const isAuth = () => {
  return localStorage.getItem('user') !== null && localStorage.getItem('userRole') !== null;
}

const compile = (parentRoute, subRoutes) => {
  return subRoutes.flatMap(subRoute => {
      const newRoute = {
          'name': subRoute.name,
          'handleToast': subRoute.handleToast,
          'path': parentRoute.path + subRoute.path,
          'cookies': subRoute?.cookies,
          'component': subRoute.component,
          'roles': (parentRoute.roles || []).concat((subRoute.roles || [])),
          'userRole': subRoute.userRole
      };
      return (subRoute.routes) ? [...compile(newRoute, subRoute.routes)] : newRoute;
  });
}

export const getRoutes = (routes) => {
  const parentRoute = {
      'name': '',
      'path': '',
  };
  const flatRoutes = compile(parentRoute, routes);
  return flatRoutes;
}

export const LisaRoute = ({ roles, children }) => {
  roles = roles || [];

  if (!isAuth()) {
    return <Navigate to={'/login'} replace />
  }

  if (hasRoles(roles)) {
    return children;
  }

  return (
      <>
        <Unauthorized />
      </>
  );
}

export const SECURITY_ROLE = "ROOT";
export const ADMIN_ROLE = "ADMIN";
export const USER_ROLE = "USER";