import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PhoneInput from 'react-phone-number-input'

import '../styles/FormCalendar.css'
import 'react-phone-number-input/style.css'

import { EventUserData, EventUserDataToSend } from "../type/UserDataEvent";
import { getUserInfo4MeetingById, runMeetingEvent } from "../api/connect2llm";
import { Redirect2NewPage } from "../Helpers/Redirect2";
import { LoaderResponse } from "../components/Loader";

const FormCalendar = () => {
    const timer = "/images/icone/timer.png"
    const calendar = "/images/icone/calendar.png"

    const [requestStatus, setRequestStatus] = useState(false);
    const [firstname, setFirstname] = useState<string>("")
    const [lastname, setLastname] = useState<string>("")
    const [userDataEvent, setUserDataEvent] = useState<EventUserData>();
    const [userDataToSend, setUserDataEventToSend] = useState<EventUserDataToSend>({
        user_id: "",
        firstname: "",
        lastname: "",
        email_adress: "",
        creneauChoosed: "",
        duration_of_meeting: "",
        phone_number: ""
    });

    const params = useParams();
    const user_id = params.id

    const updateUserId = (val: string) => {
        setUserDataEventToSend(prevState => ({ ...prevState, user_id: val }));
    }

    const updateFirstName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setFirstname(val.target.value)
        setUserDataEventToSend(prevState => ({ ...prevState, firstname: val.target.value }));
    }

    const updateLastName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setLastname(val.target.value)
        setUserDataEventToSend(prevState => ({ ...prevState, lastname: val.target.value }));
    }

    const updateMeetingDuration = (val: string) => {
        setUserDataEventToSend(prevState => ({ ...prevState, duration_of_meeting: val }));
    }

    const updatePhoneNumber = (val: string) => {
        if(val !== undefined) setUserDataEventToSend(prevState => ({ ...prevState, phone_number: val }));
    }

    const updateCreneau = (val: React.ChangeEvent<HTMLSelectElement>) => {
        setUserDataEventToSend(prevState => ({ ...prevState, creneauChoosed: val.target.value }));
    }

    const updateEmailAdress = (val: string) => {
        setUserDataEventToSend(prevState => ({ ...prevState, email_adress: val }));
    }

    const CreneauView = (Data: Array<string> | undefined) => {
        let optionView: Array<JSX.Element> = [
            <option key={-1} value="">--Veuillez choisir le créneau--</option>
        ];

        if(Data) {
            for(let idx = 0; idx < Data.length; idx++){
                optionView.push(
                    <option key={idx} value={Data[idx]}>{Data[idx]}</option>
                )
            }
            return <div>
                <select id="custom-select" onChange={(val: React.ChangeEvent<HTMLSelectElement>) => updateCreneau(val)} required={true}>
                    {optionView}
                </select>
            </div>
        }
    }

    const getUserRDV = () => {
        let data: EventUserData = {
            user_id: user_id || "",
            firstname: "",
            lastname: "",
            email_adress: "",
            creneau: [
                "", 
                "", 
                ""
            ],
            duration_of_meeting: ""
        }

        if(user_id !== undefined) {
            getUserInfo4MeetingById(user_id, "Stéphane").then((response: any) => {
                let res_data = response.data.res
                console.log("data: ", res_data)
                if(res_data !== null) data = res_data
                setUserDataEvent(data)
                
                updateUserId(data.user_id)
                setFirstname(data.firstname)
                setLastname(data.lastname)
                updateEmailAdress(data.email_adress)
                updateMeetingDuration(data.duration_of_meeting)
            }).catch((e) => {
                
            })
            
            setUserDataEventToSend(prevState => ({ ...prevState, user_id: user_id }));
        }
    }

    const scheduleEvent = () => {
        // Basic validation before submitting the form
        if (!userDataToSend.firstname || !userDataToSend.lastname){
            setUserDataEventToSend(prevState => ({ ...prevState, firstname: firstname }));
            setUserDataEventToSend(prevState => ({ ...prevState, lastname: lastname }));
        }
        
        if(!userDataToSend.email_adress || !userDataToSend.phone_number || !userDataToSend.creneauChoosed || !userDataToSend.duration_of_meeting) {
            console.log("pre: ", userDataToSend)
            alert("Champ incomplète !!!")
            return;
        }

        // Proceed with scheduling the event
        // let res = "Rendez-vous planifier pour ID: "
        // console.info("Data to send: ", res, userDataToSend)

        setRequestStatus(true)
        const response = runMeetingEvent("Stéphane", userDataToSend)
        response.then(() => {
            setRequestStatus(false)
            Redirect2NewPage("Stéphane", "/info")
        })
    }

    useEffect(() => {
        if(user_id !== undefined) getUserRDV();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div id="form-container">
            <div id="form-left-content">
                <div id="title">Projet IA</div>
                <form id="creneau-container">
                    <div className="row-1">
                        <div className="col-1-3">
                            <div className="left-picto"><img alt="Temps" className="picto-meeting" src={timer} /></div>
                            <div className="right-text">{userDataEvent?.duration_of_meeting}</div>
                        </div>
                    </div>
                    <div className="row-1">
                        <div className="col-1-3">
                            <div className="left-picto"><img alt="CLD" className="picto-meeting" src={calendar} /></div>
                            <div className="right-text">
                                {CreneauView(userDataEvent?.creneau)}
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{display: "flex", flex: 1}}></div>
            </div>

            <div id="form-separator-line"></div>

            <div id="form-right-content">
                <div style={{display: 'flex', flex: 2, flexDirection : 'column'}}>
                    <div id="title">Les informations à remplir</div>
                    <div id="form-input">
                        <div className="col1">
                            <div className="row">
                                <label>Votre prénom *</label>
                                <input type="text" value={firstname} placeholder="Prénom" style={{width: '95%'}} onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateFirstName(val)} required={true} />
                            </div>
                            <div className="row">
                                <label>Votre nom *</label>
                                <input type="text" value={lastname} placeholder="Nom" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateLastName(val)} required={true}/>
                            </div>
                        </div>
                        <div className="row">
                            <label>Email *</label>
                            <input type="email" value={userDataEvent?.email_adress || ""} disabled placeholder="Adresse e-mail" required={true}/>
                        </div>
                        <div className="row">
                            <label>Numéro de contact *</label>
                            <PhoneInput
                                placeholder="Enter phone number"
                                // value={phoneValue}
                                onChange={(val: string) => updatePhoneNumber(val)}/>
                        </div>
                    </div>

                    <div id="form-btn-container">
                        {
                            requestStatus ? (
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <LoaderResponse colorValue='green' />
                                    <p style={{color: 'green'}}>Enregistrement en cours...</p>
                                </div>
                            ) : (
                                <button id="form-btn" onClick={scheduleEvent}>Planifier l'événement</button>
                            )
                        }
                    </div>
                </div>

                <div style={{display: 'flex', flex: 1, fontWeight: 'bold'}}>
                    * : Obligatoire
                </div>
            </div>
        </div>
    )
}

export default FormCalendar