import React, { useState } from 'react'
import MenuListProps from '../interface/MenuListInterface'

// import "../styles/DropDownMenu.css"

const Submenu: React.FC<MenuListProps> = ({ MenuListElement }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownVisible(false);
    };
    
    // const submenu_view = () => {
    //     let submenu_list: Array<JSX.Element> = []

    //     for (let idx = 0; idx < MenuListElement.length; idx++) {
    //         submenu_list.push(
    //             <li key={idx} className="nav__menu-item">
    //                 <a>{MenuListElement[idx]}</a>
    //             </li>
    //         )
    //     }

    //     return submenu_list
    // }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="dropdown-container"
            >
            {isDropdownVisible && (
                <div className="dropdown">
                <ul className="dropdown-list">
                    <li>Home</li>
                    <li>Signout</li>
                </ul>
                </div>
            )}
            </div>
        // <nav className="nav">
        //     <ul className="nav__menu">
        //         {submenu_view()}
        //     </ul>
        // </nav>
    )
}

export default Submenu