import React from "react";
import "../styles/TabMenu.css"

import TabsMenuProps from "../interface/TabsInterface";
import { Redirect2NewPage } from "../Helpers/Redirect2";

const TabsMenu: React.FC<TabsMenuProps> = ({model_name, firstTabName="Tab 1", secondTabName="Tab 2", updateParentState}) => {

    const prompt_icon = "/images/icone/touch_on.svg"
    const mic_off = "/images/icone/mic_off.svg"
    // const mic_on = "/images/icone/mic_on.svg"
    const home_icon = "/images/icone/chevron_icon.svg"
    const line_separator = "/images/icone/line_separate.svg"

    const [activeTab, setActiveTab] = React.useState("tab1");

    //  Functions to handle Tab Switching
    const handleTab = (elt: string) => {
        // update the state to elt
        setActiveTab(elt);
        updateParentState(elt);

        if(elt !== 'tab1' && elt !== 'tab2' && elt === "Stéphane") {
            Redirect2NewPage(elt)
        }
    };

    return (
        <div style={{flex: 9}}>
            <ul className="nav-menu">
                <li className="no-border"
                    onClick={() => handleTab("accueil")}
                >
                    <img src={home_icon} alt="Accueil" width={20} height={25} />
                    <p style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: 'normal', color: 'white'}}>
                        Accueil
                    </p>
                </li>
            </ul>
            <ul className="nav-menu">
                <li
                    className={activeTab === "tab1" ? "active" : "deactive"}
                    onClick={() =>{ 
                        setActiveTab("tab1");
                        updateParentState("tab1")}}
                >
                    <img src={prompt_icon} alt="Prompt" width={25} height={25} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>{firstTabName}</p>
                </li>

                <li
                    style={{cursor: 'default'}}
                    className={activeTab === "tab2" ? "active" : "deactive"}
                    //className="grise"
                    onClick={() => {
                        setActiveTab("tab2");
                        updateParentState("tab2")}
                    }
                >
                    <img src={mic_off} alt="Voice" width={20} height={25} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>{secondTabName}</p>
                </li>
                 <li className="no-css-image">
                    <img src={line_separator} alt="ls" width={20} height={25} />
                </li>
            </ul>

            {/* <div className="outlet">
                {activeTab === "tab1" ? <TabEcrit model_name={model_name} random_id={random_id} updateParentState={updateParentState} llmStat={llmStat} /> : <TabOrale model_name={model_name} random_id={random_id} />}
            </div> */}

            <ul className="nav-menu">
                <li
                    className={model_name === "Stéphane" ? "active" : "deactive"}
                    onClick={() => handleTab("Stéphane")}
                >
                    <img src="/images/icone/Stéphane.png" alt="sté" width={35} height={35} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>Stéphane</p>
                </li>

                <li
                    className={model_name === "Adriana" ? "active" : "deactive"}
                    onClick={() => handleTab("Adriana")}
                >
                    <img src="/images/icone/Adriana.png" alt="adr" width={35} height={35} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>Adriana</p>
                </li>

                <li
                    className={model_name === "Nakamoto" ? "active" : "deactive"}
                    onClick={() => handleTab("Nakamoto")}
                >
                    <img src="/images/icone/Nakamoto.png" alt="nkt" width={35} height={35} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>Nakamoto</p>
                </li>
                
                <li
                    className={model_name === "Dr Violet" ? "active" : "deactive"}
                    onClick={() => handleTab("Dr Violet")}
                >
                    <img src="/images/icone/Dr Violet.png" alt="dr" width={35} height={35} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>Dr Violet</p>
                </li>
                
                <li
                    className={model_name === "Aurélia" ? "active" : "deactive"}
                    onClick={() => handleTab("Aurélia")}
                >
                    <img src="/images/icone/Aurélia.png" alt="aur" width={35} height={35} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>Aurélia</p>
                </li>

                <li
                    className={model_name === "Pr Violet" ? "active" : "deactive"}
                    onClick={() => handleTab("Pr Violet")}
                >
                    <img src="/images/icone/Pr Violet.png" alt="pr" width={35} height={35} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>Pr Violet</p>
                </li>
                <li
                    className={model_name === "Lisa" ? "active" : "deactive"}
                    onClick={() => handleTab("Lisa")}
                >
                    <img src="/images/icone/Lisa.png" alt="Lisa" width={35} height={35} />
                    <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>Lisa</p>
                </li>
            </ul>

        </div>
    );
};
export default TabsMenu;
