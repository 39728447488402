import React, { useState, useRef } from 'react';

const AudioRecorder: React.FC = () => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [isRecording, setIsRecording] = useState(false);
  const [socket, setSocket] = useState<WebSocket | null>(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.start();

      const audioChunks: Blob[] = [];

      mediaRecorder.addEventListener("dataavailable", (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      });
      setIsRecording(true)

      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm; rate=44100' });
        const socket = new WebSocket("ws://localhost:8765");
        socket.binaryType = "arraybuffer";

        socket.addEventListener('open', () => {
          socket.send(audioBlob);
        });
      });

      // setTimeout(() => {
      //   mediaRecorder.stop();
      // }, 3000); // Record for 3 seconds
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
  };

  const connectWebSocket = () => {
    const ws = new WebSocket('ws://localhost:8765'); // Replace with your Python server address
    setSocket(ws);

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onclose = () => {
      console.log('WebSocket closed');
    };
  };

  const sendAudioDataToServer = (audioBlob: Blob) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const binaryData = reader.result as ArrayBuffer;
        if (binaryData.byteLength > 0) {
          socket.send(binaryData);
        } else {
          console.error("Empty audio data");
        }
      };
      reader.readAsArrayBuffer(audioBlob);
    }
  };

  return (
    <div>
      <button onClick={startRecording} disabled={isRecording}>
        Start Recording
      </button>
      <button onClick={stopRecording} disabled={!isRecording}>
        Stop Recording
      </button>
      <button onClick={connectWebSocket} disabled={socket !== null}>
        Connect WebSocket
      </button>
    </div>
  );
};

export default AudioRecorder;
