import { text } from 'stream/consumers';
import { Redirect2NewPage } from '../Helpers/Redirect2';
import { MODEL_DESCRIPTION, MODEL_TITLE } from '../constants/ArrayData';
import  '../styles/Button.css';
import  '../styles/Card2.css';

// Ajoutez une interface pour spécifier le type de propriétés que SwitchButton accepte
interface SwitchButtonProps {
    id?: string;
    name: string;
  }

export const SwitchButton: React.FC<SwitchButtonProps> = ({name, id}) => {
  const image_path = "/images/ia/" + name + ".png"
  const image_description = MODEL_DESCRIPTION[MODEL_TITLE.indexOf(String(name))]

  const handleClick = (choix: string) => {
      // Ouvrir une nouvelle fenêtre avec l'argument
      Redirect2NewPage(choix, "", id)
    };
  
  return ( 

    <button 
      disabled={name !== "Stéphane" ? true : false} 
      style={{cursor: name !== "Stéphane" ? "not-allowed" : "pointer"}} 
      className="btn-container" 
      onClick={() => handleClick(name)}
      >
        <div className="card">
            <div className="image-container">
                <img src={image_path} alt={name} className="card-image" />
                <div className="overlay">
                <h2 className="title">{name}</h2>
                <p className="description">{image_description}</p>
                </div>
            </div>
        </div>
    </button>
  ) 
}