import FormInput from "../FormInput";
import React from "react";

interface TabsProps {
  model_name: string;
  random_id: string;
  updateParentState?: any;
  llmStat?: boolean | undefined;
  LoaderStateDefaultValue?: boolean;
}

export const TabEcrit: React.FC<TabsProps> = ({
  model_name,
  random_id,
  updateParentState,
  llmStat = false,
  LoaderStateDefaultValue = false,
}) => {
  
  return (
    <div className="FirstTab">
      <FormInput
      updateParentState={updateParentState}
      model_name={model_name}
      random_id={random_id}
      llmStat={llmStat}
      LoaderStateDefaultValue={LoaderStateDefaultValue}
      />
    </div>
  );
};
