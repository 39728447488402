import { count } from 'console';
import React, { useEffect, useState } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';

const Visualizer = ({isRecording}:{ isRecording:any}) => {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

  // Example function to set media recorder
  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
     .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(mediaRecorder);
        mediaRecorder.start();
      })
     .catch(err => console.error('Error accessing media devices.', err));
  };
  useEffect(() => {
    startRecording()
  }, [isRecording.current]);

  return (
    <div className='live'>
      {mediaRecorder && isRecording.current==true && (
        <LiveAudioVisualizer
          mediaRecorder={mediaRecorder}
          width={150}
          height={60}
          barColor={"rgb(0, 255, 0)"}
        />
      )}
      
    </div>
  );
}

export default Visualizer;