import React, { useEffect, useState } from 'react';
import { sendPromptToLLM, getConversationHistoryById } from '../../api/connect2llm';

const AudioRecorder = ({
  random_id,
  model_name,
  setHistory,
  setShowHistory,
  isRecording,
  setisStateRecording,
  setIsLoading,
  setUrlSpecter
}: {
  random_id: string,
  model_name: string,
  setHistory: any,
  setShowHistory: any,
  isRecording: any,
  setisStateRecording: any, 
  setIsLoading: any,
  setUrlSpecter: any
}) => {

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const micOffSvg = "/images/icone/mic_on1.svg";
  const micOnSvg = "/images/icone/mic_off.svg";

  const convertBlobToBase64 = (blob: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  };

  function createBlobUrl(base64data: string, mimeType: string): string {
    const binaryString: string = window.atob(base64data);
    const bytes: Uint8Array = new Uint8Array(binaryString.length);

    for (let i: number = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob: Blob = new Blob([bytes], { type: mimeType });

    const url: string = URL.createObjectURL(blob);

    return url;
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      let chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (event) => {
        console.log("mandalo ato ve")
        if (event.data) {
          chunks.push(event.data);
        }
      };
      
      mediaRecorder.onstop = async () => {
        
        setIsLoading(true);
        const blob = new Blob(chunks, { type: 'audio/wav' });
        const base64String = await convertBlobToBase64(blob);
        sendPromptToLLM(random_id, base64String, model_name).then((response: any) => {
          console.log("response: " + JSON.stringify(response));
          setUrlSpecter(createBlobUrl(response.data.audio, 'audio/mpeg')); 
          getConversationHistoryById(random_id, model_name).then((resp: any) => {
            let array_d = resp.data.history;
            setHistory(array_d);
            setShowHistory(true);
            setIsLoading(false);
          }).catch((err) => console.log(err));
        });
      };

      mediaRecorder.start();
      setMediaRecorder(mediaRecorder);

    } catch (error) {
      console.error('Erreur lors de l\'accès à l\'appareil audio:', error);
    }
  };

  const stopRecording = async () => {
    setIsLoading(true);
    if (mediaRecorder) {
      mediaRecorder.stop();
    } else {
      console.error('Erreur lors de l\'arrêt de l\'enregistrement:');
    }
  };
  const manageRecording = async () => {
    isRecording.current =!isRecording.current;
    console.log("isRecording2", isRecording.current);
    if (isRecording.current) {
      await startRecording();
      setisStateRecording(true);
    } else {
      await stopRecording();
      setisStateRecording(false);
    }
  }

  useEffect(() => {
    const handleSpacePress = async (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        const element = document.getElementsByClassName("clickR")[0];
        if (element && element instanceof HTMLElement) {
          element.click();
        }
      }
    };

    document.addEventListener('keydown', handleSpacePress);
    return () => {
      document.removeEventListener('keydown', handleSpacePress);
    };
  }, []);

  console.log("isRecording1", isRecording.current);

  return (
    <div id='micro'>
      <img className="clickR" onClick={manageRecording} style={{ borderColor: '#20a62f', cursor: 'pointer' }} width={50} height={50} src={isRecording.current? micOnSvg : micOffSvg} alt="Microphone" />
    </div>
  );
};

export default AudioRecorder;
