import { Rings } from 'react-loader-spinner'

export const LlmStatusLoader = () => {
    return (
        <Rings
            height="70"
            width="70"
            color="white"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
            />
    )
}