import Visualizer from "./Visualiser";
import { useRef, useState } from "react";
import AudioRecorder from "./AudioRecorder";
import { TMicroPhone } from "../../type/Microphone";
import '../../styles/Micro.css'
 

export default function MicroPhone({data} : { data : TMicroPhone}) {
    const isRecording = useRef(false);
    const [isStateRecording, setisStateRecording] = useState(false);
    return (
        <div className="audio">
          {isStateRecording ? (
              <div>
                <Visualizer isRecording={isRecording} />
              </div> 
            ): <div style={{width:"125px"}}></div>}
            <div>
                <AudioRecorder
                  random_id={data.random_id}
                  model_name={data.model_name}
                  setHistory={data.setHistory}
                  setShowHistory={data.setShowHistory}
                  isRecording={isRecording}
                  setisStateRecording={setisStateRecording}
                  setIsLoading={data.setisLoadingAudioRecording}
                  setUrlSpecter={data.setUrlSpecter}
                />
            </div>
            
        </div>
    )
}