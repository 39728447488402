import Specter from "./Specter";
import Visualizer from "./Visualiser";
import { LoaderResponse } from "../Loader";
import AudioRecorder from "./AudioRecorder";
import React, { useState, useRef } from "react";
import { AudioStartStop } from "../AudioStartStop";
import TabsInterface from "../../interface/TabsInterface";

interface TabsProps {
  model_name: string;
  random_id: string;
  updateParentState?: any;
  llmStat?: boolean | undefined;
  LoaderStateDefaultValue?: boolean;
  setHistory?: any;
  setShowHistory?: any;
  isLoadingAudioRecording : boolean;
  urlSpecter : string 
}



export const TabOrale: React.FC<TabsProps> = ({ model_name, random_id, setHistory, urlSpecter , isLoadingAudioRecording }) => {
  
  return (
    <>
      {isLoadingAudioRecording ? (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <LoaderResponse colorValue="darkgreen" />
        </div>
      ) : (
        <div className="SecondTab">

          <Specter url={
            urlSpecter
          } />
          {/* {isStateRecording && (
            <Visualizer isRecording={isRecording} />
          )}
          <AudioRecorder
            random_id={random_id}
            model_name={model_name}
            setHistory={setHistory}
            setShowHistory={setShowHistory}
            isRecording={isRecording}
            setisStateRecording={setisStateRecording}
            setIsLoading={setisLoadingAudioRecording}
            setUrlSpecter={setUrlSpecter}
          /> */}
        </ div>)

      }
    </>
  );
};