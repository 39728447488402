import React from "react";
import { Link, useParams } from "react-router-dom";

import "../styles/Information.css"
import { MESSAGE, MODEL_TITLE } from "../constants/ArrayData";

const GenerateInformation = () => {

    const params = useParams();
    const llm_name = params.model_name
    const index = MODEL_TITLE.indexOf(String(llm_name))

    if(index === -1) {
        window.location.replace('*');
    }
    const text = MESSAGE[index]

    return (
        <div className="info-content-container">
            <h1>Message de {llm_name}</h1>
            <p id="text-p">{text}</p>
            <Link to='/'>D'accord.</Link>
        </div>
    )
}

export default GenerateInformation